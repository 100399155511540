.pagination-container {
    display: flex;
    list-style-type: none;
  }
  .pagination-container .pagination-item {
    padding: 0 12px;
    text-align: center;
    margin: auto 4px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    font-size: 14px;
    min-width: 32px;



    color: #222222;
    height: 36px;
    width: 36px;
    text-align: center;
    line-height: 36px;
    background-color: #f5f5f5;
    border-radius: 50%;
  }
  .pagination-container .pagination-item .pe-7s-angle-left{
    font-size: 30px;
    line-height: 0.90;
  }
  .pagination-container .pagination-item .pe-7s-angle-right{
    font-size: 30px;
    line-height: 0.90;
  }
  /* .pagination-container .arrows:hover{
    cursor: pointer;
  } */
  .pagination-container .pagination-item.dots:hover {
    background-color: #f5f5f5;
    color: #222222;
    cursor: default;
  }
  .pagination-container .pagination-item:hover {
    background-color: #c29958;
    color: #fff;
    cursor: pointer;
  }
  .pagination-container .pagination-item.selected {
    background-color: #c29958;
    color: #fff;;
  }
  .pagination-container .pagination-item .arrow::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  }
  .pagination-container .pagination-item .arrow::before:hover{
    border-right: 0.12em solid #fff;
    border-top: 0.12em solid #fff;
  }

  .pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
  }
  .pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
  }
  .pagination-container .pagination-item.disabled {
    pointer-events: none;
  }
  .pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }
  .pagination-container .pagination-item.disabled:hover {

    background-color: transparent;
    cursor: default;
  }
