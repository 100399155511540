body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bookclose,
.product-menu-conten li{
  cursor: pointer;
}

.productLabel{
  height: 55px;
}

.buttonWrapper{
  width: 100%;
  height: 100%;
  text-align: center;
}

.shopButton{
  /* width:200px !important;  */
  color: #151515;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 18px;
  border: none;
  transition: 0.5s;
  overflow: hidden;
  background-color: #e0cd2e;
  margin-top: 10%;
}

.flip-card .card {
  height: 200px;
  /* width: 300px !important; */
  /* margin-left: 20px; */
  border-color: #c29958 !important;
  /* background: #be8a36ac; */
}

.flip-card {
  background-color: transparent;
  perspective: 500px;
}

.flip-card h1,
.flip-card h2,
.flip-card h3,
.flip-card h4,
.flip-card h5,
.flip-card h6 {
  font-family: "Times New Roman", Times, serif !important;
}

.flip-card-inner {
  position: relative;
  /* width: 50%; */
  /* height: 100%; */
  /* text-align: center; */
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 2px 7px 7px 2px rgba(0, 0, 0, 0.2);
}

/* .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg)
} */

/* .flip-card:hover .flip-card-inner .flip-card-front {
  display: none;
} */


/* .flip-card:hover .flip-card-inner .flip-card-back {
  display: block;
} */

.flip-card-front,
.flip-card-back {
  position: relative;
  /* width: 100%;
  height: 100%; */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  transition: transform 3.6s;
  transform-style: preserve-3d;
  display: block;
  /* background-color: #bbb; */
  /* color: black; */
}

.flip-card-back {
  transform: rotateY(180deg);
  display: none;
}

.flip-card-back .card{
    background-color: #f5f5f5;
}
.rounded-circle{
  height: 125px !important;
}

.first-footer .email button[type=submit]:hover{
  background:rgba(245,255,255,.1) !important;
}