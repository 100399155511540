.header-map.google-maps {
	background: #e1e5f1;
	padding: 0;
	height: 520px;
	position: static;
}
.header-map.google-maps .container {
	position: static;
}
#map-leaflet {
	width: 100%;
	height: 520px;
	position: absolute;
	z-index: 0;
}
.info-box-img {
	width: 180px;
	margin-right: 20px;
	float: left;
}
.info-box p {
	color: black;
}
.header-map.google-maps.horizontal,
.header-map.google-maps.horizontal #map-canvas {
	height: 520px;
	position: static;
}
.map-leaflet-wrapper,
.map-google-wrapper {
	position: absolute;
}
#map-leaflet,
#map-google {
	width: 100%;
	height: 755px;
	position: absolute;
	z-index: 0;
}
.map-pull-top #map-leaflet,
.map-pull-top #map-google {
	margin-top: -180px;
}
#map-leaflet.full,
#map-google.full {
	display: table;
	height: 100%;
	width: 100%;
}
.map-marker {
	height: 50px;
	width: 50px;
}
.leaflet-div-icon,
.map-marker {
	background-color: #fa2964;
	border: 5px solid #fa2964;
	border-radius: 50%;
	cursor: pointer;
	position: relative;
	transition: all .15s linear;
	z-index: 10;
}
.leaflet-div-icon:before,
.map-marker:before {
	border: 4px solid transparent;
	border-width: 10px 8px;
	border-top-color: #fa2964;
	bottom: -24px;
	height: 0;
	content: '';
	display: block;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 0;
	z-index: 11;
}
.leaflet-div-icon:after,
.map-marker:after {
	background-color: #fff;
	border-radius: 50%;
	bottom: 0;
	box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 12;
}
.leaflet-div-icon i,
.map-marker i {
	color: #000;
	font-size: 23px;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 13;
}
.leaflet-div-icon:hover,
.map-marker:hover {
	top: -2px;
}
.map-popup-content-wrapper {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
	display: none;
	left: -100px;
	top: -210px !important;
	position: absolute;
	z-index: 9999;
}
.map-popup-content-wrapper:after {
	border: 7px solid transparent;
	border-top-color: #fff;
	bottom: -14px;
	content: '';
	display: block;
	height: 0;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 0;
}
.map-popup-content-wrapper,
.leaflet-popup-content-wrapper {
	border-radius: 0;
	margin: 0;
	padding: 0;
	width: 240px;
}
.map-popup-content,
.leaflet-popup-content {
	background-color: #fff;
	height: 243px;
	margin: 0 0 8px 0;
	padding: 0;
}
.leaflet-popup-tip {
	width: 17px;
	height: 1px;
	padding: 1px;
	margin: -1px auto 0;
	transform: rotate(45deg);
}
.map-popup-content a:hover,
.leaflet-popup-content a:hover {
	text-decoration: none;
}
.map-popup-content-wrapper .close {
	color: #fff;
	display: block;
	font-size: 11px;
	opacity: 1;
	position: absolute;
	right: 15px;
	top: 15px;
	text-shadow: none;
	z-index: 99999;
}
.listing-window-image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	height: 180px;
	position: relative;
}
.listing-window-image:after {
	background-color: rgba(0, 0, 0, 0.3);
	bottom: 0;
	content: '';
	display: block;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity .15s linear;
	visibility: hidden;
	z-index: 100;
}
.listing-window-image:before {
	-webkit-font-smoothing: antialiased;
	background-color: #fa2964;
	border-radius: 50%;
	color: #fff;
	content: '\f002';
	display: block;
	font-family: 'FontAwesome';
	height: 32px;
	left: 50%;
	line-height: 30px;
	opacity: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	transition: opacity .15s linear;
	visibility: hidden;
	width: 32px;
	z-index: 101;
}
.map-popup-content-wrapper:hover .listing-window-image:before,
.map-popup-content-wrapper:hover .listing-window-image:after,
.leaflet-popup-content-wrapper:hover .listing-window-image:before,
.leaflet-popup-content-wrapper:hover .listing-window-image:after {
	opacity: 1;
	visibility: visible;
}
.listing-window-content h2,
.listing-window-content h3 {
	display: block;
	color: #323232;
}
.listing-window-content h2 {
	display: block;
	font-size: 1.1em;
	font-weight: 700;
	line-height: 1.5;
	margin: 8px 15px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.listing-window-content p {
	display: block;
	font-size: 1.1em;
	font-weight: 500;
	color: #000;
	line-height: 1.5;
	margin: 4px 15px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.listing-window-content h3 {
	background-color: rgba(0, 0, 0, 0.5) !important;
	border-radius: 3px;
	color: #fff;
	display: inline-block;
	font-size: 1em;
	font-weight: 700;
	left: 15px;
	margin: 0;
	padding: 4px 8px;
	position: absolute;
	top: 15px;
}
.marker-cluster-small {
	background-color: rgba(250, 41, 100, 0.6);
	color: #fff;
	font-weight: 700;
}
.marker-cluster-small div {
	background-color: rgba(250, 41, 100, 0.6);
	color: #fff;
	font-weight: 700;
}
.marker-cluster-medium {
	background-color: rgba(250, 41, 100, 0.6);
	color: #fff;
	font-weight: 700;
}
.marker-cluster-medium div {
	background-color: rgba(250, 41, 100, 0.6);
	color: #fff;
	font-weight: 700;
}
.marker-cluster-large {
	background-color: rgba(250, 41, 100, 0.6);
	color: #fff;
	font-weight: 700;
}
.marker-cluster-large div {
	background-color: rgba(250, 41, 100, 0.6);
}


.infoBox {
	border-radius: 4px
}
.map-box {
	background-color: #fff;
	margin-bottom: 20px;
	border-radius: 4px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .12);
	position: relative
}
.map-box .listing-img-container img {
	width: 100%;
	border-radius: 4px 4px 0 0
}
.map-box h4 {
	margin: 0;
	padding: 0
}
.map-box h4 a {
	padding: 0 0 2px;
	font-size: 17px;
	line-height: 25px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	display: inline-block
}
.listing-img-container {
	position: relative;
	height: 100%;
	display: block
}
.map-box h4 a:hover {
	color: #66676b
}
.map-box p {
	padding: 0;
	line-height: 25px;
	margin: 2px 0 0;
	font-size: 14px
}
.map-box ul.listing-details {
	padding: 0;
	font-size: 14px;
	display: none
}
.map-box .listing-title {
	padding: 16px 22px
}
.map-box .listing-img-content {
	padding: 18px 22px
}
.map-box .listing-img-content span {
	font-size: 21px
}
.map-box .listing-img-content .listing-price i {
	font-size: 15px;
	margin: -7px 0 0
}
.infoBox>img {
	position: absolute!important;
	right: 0;
	top: 0
}
.map-box .listing-img-container::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	display: block;
	background: linear-gradient(to top, rgba(35, 35, 37, 0.85) 0%, rgba(35, 35, 37, 0.40) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
	background-color: rgba(35, 35, 37, .2)
}
.map-box .listing-item-content {
	position: absolute;
	bottom: 20px;
	left: 0;
	padding: 0 24px;
	width: 100%;
	z-index: 50;
	box-sizing: border-box
}
.map-box .listing-item-content h3 {
	color: #fff;
	font-size: 18px;
	padding: 0 0 2px;
	font-weight: 500;
	margin: 0;
	line-height: 27px
}
.map-box .listing-item-content span {
	font-size: 15px;
	font-weight: 300;
	display: inline-block;
	color: rgba(255, 255, 255, .7)
}
.infoBox-close {
	position: absolute;
	top: 10px;
	right: 10px;
	display: inline-block;
	z-index: 999;
	text-align: center;
	line-height: 29px;
	cursor: pointer;
	font-size: 15px;
	font-weight: 500;
	height: 29px;
	width: 29px;
	background-color: #fff;
	color: #333;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	font-family: fontawesome;
	border-radius: 50%;
	-webkit-text-stroke: 1px #fff
}
.infoBox-close:hover {
	color: #fff;
	background-color: #66676b;
	-webkit-text-stroke: 1px #66676b
}
.map-box-icon {
	height: 38px;
	width: 38px;
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: #3685cf;
	display: block;
	color: #fff;
	font-size: 12px;
	z-index: 101;
	text-align: center;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-transform: translateX(50%);
	-ms-transform: translateX(50%);
	transform: translateX(50%);
	opacity: 0;
	visibility: hidden
}
.map-box-icon:before {
	content: "\f064";
	font-family: fontawesome;
	position: relative;
	top: 7px;
	display: block
}
.map-box-image:hover .map-box-icon {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateX(0)
}
.map-box-image {
	position: relative;
	overflow: hidden;
	display: block
}
.infoBox {
	-webkit-animation: fadeIn .9s;
	animation: fadeIn .9s
}
@-webkit-keyframes fadeIn {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}
@keyframes fadeIn {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}
.infoBox .star-rating {
	font-size: 16px;
	margin-top: 1px
}
.infoBox .star-rating span {
	margin-right: 3px
}
.infoBox .rating-counter {
	position: relative;
	top: -1px
}
.infoBox:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #fff
}
#map-leaflet {
	height: 100%;
	width: 100%;
	margin: 0;
	z-index: 1;
}
#map-container {
	position: relative;
	top: 0;
	left: 0;
	height: 520px;
	width: 100%;
	z-index: 8;
	margin-bottom: 60px
}
.gmnoprint {
	margin: 10px
}
#map-container.fullwidth-home-map {
	height: 690px;
	margin-bottom: 0
}
#map-container.fullwidth-home-map .main-search-inner {
	position: absolute;
	display: block;
	bottom: 60px;
	top: auto;
	transform: none;
	padding-bottom: 0;
	z-index: 9999;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%
}
.dark-overlay .main-search-inner {
	bottom: 315px !important;
}
#map-container.fullwidth-home-map .main-search-input {
	margin-top: 0
}
.cluster img {
	display: none
}
.cluster-visible {
	text-align: center;
	font-size: 15px!important;
	color: #fff!important;
	font-weight: 500;
	border-radius: 50%;
	width: 36px!important;
	height: 36px!important;
	line-height: 36px!important;
	background-color: #fa2964;
}
.cluster-visible:before {
	border: 7px solid #fa2964;
	opacity: .2;
	box-shadow: inset 0 0 0 4px #fa2964;
	content: '';
	position: absolute;
	border-radius: 50%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: cluster-animation 2.5s infinite
}
@keyframes cluster-animation {
	0%,
	100% {
		transform: scale(1.3) rotate(0deg)
	}
	50% {
		transform: scale(1.5) rotate(90deg)
	}
}
.map-marker-container {
	position: absolute;
	margin-top: 10px;
	transform: translate3d(-50%, -100%, 0)
}
.marker-container {
	position: relative;
	margin: 10px auto;
	width: 50px;
	height: 50px;
	z-index: 1;
	border-radius: 50%;
	cursor: pointer;
	top: -5px
}
#singleListingMap .marker-container {
	cursor: default
}
.marker-container {
	perspective: 1000
}
.marker-card {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: all .4s ease-in-out;
	position: absolute;
	z-index: 1
}
.marker-container:hover .marker-card,
.map-marker-container.clicked .marker-card {
	transform: rotateY(180deg) translateY(-4px)
}
.marker-arrow {
	width: 0;
	content: "";
	height: 0;
	border-style: solid;
	border-width: 8px 4px 0;
	border-color: #fa2964 transparent transparent;
	top: 49px;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	border-radius: 50%;
	z-index: 1;
}
.face {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	text-align: center;
	border-radius: 50%;
	color: #fff;
	z-index: 100
}
.face i {
	line-height: 34px;
	font-size: 22px;
	margin-top: .2rem;
	margin-bottom: 0px;
}
.face.front {
	background-color: #fff;
	border: 6px solid #fa2964;
	color: #121B22;
	z-index: 100
}
.face.back {
	transform: rotateY(180deg);
	box-sizing: border-box;
	background: #fa2964;
	border-color: #fa2964;
	color: #fff
}
.face.back i {
	transform: scaleX(-1);
	line-height: 48px;
	font-size: 22px;
	margin-top: .2rem;
}
.face.back:after {
	content: "";
	display: block;
	width: 36px;
	height: 36px;
	top: 2px;
	left: 2px;
	border-radius: 50%;
	position: absolute
}
.custom-zoom-in,
.custom-zoom-out {
	background-color: #fff;
	color: #333;
	cursor: pointer;
	border-radius: 50%;
	margin: 5px 15px;
	transition: color .3s, background-color .3s;
	box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .2);
	text-align: center;
	font-size: 15px;
	height: 34px;
	width: 34px;
	display: none;
}
.custom-zoom-in:hover,
.custom-zoom-out:hover {
	background-color: #66676b;
	color: #fff
}
.custom-zoom-in:before,
.custom-zoom-out:before {
	font-family: fontawesome;
	width: 100%;
	line-height: 35px;
	transition: -webkit-text-stroke .3s;
	-webkit-text-stroke: 1px #fff
}
.zoomControlWrapper {
	position: absolute;
	left: 0;
	right: auto;
	width: 70px
}
.custom-zoom-in:before {
	content: "\f067"
}
.custom-zoom-out:before {
	content: "\f068"
}
.custom-zoom-in:hover:before,
.custom-zoom-out:hover:before {
	-webkit-text-stroke: 1px #66676b
}
#mapnav-buttons {
	position: absolute;
	transform: translate(0, 0);
	z-index: 999;
	font-size: 14px;
	display: inline-block;
	bottom: 20px;
	right: 20px;
	list-style: none;
	padding: 0
}
#mapnav-buttons.top {
	top: 20px;
	right: 20px;
	bottom: auto
}
#mapnav-buttons li {
	float: left;
	margin-left: 4px;
	line-height: 20px
}
#streetView,
#geoLocation,
#scrollEnabling,
#mapnav-buttons a {
	color: #333;
	background-color: #fff;
	padding: 7px 18px;
	padding-top: 9px;
	font-weight: 500;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-transition: all .2s ease-in-out;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 50px;
	box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .2)
}
#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled,
#mapnav-buttons a:hover {
	background-color: #66676b;
	color: #fff
}
#prevpoint:before,
#nextpoint:after {
	font-family: fontawesome;
	position: relative;
	font-weight: 500;
	margin: 0 0 0 6px;
	font-size: 17px;
	top: 0;
	line-height: 1px
}
#prevpoint:before {
	content: "\f104";
	margin: 0 6px 0 0
}
#nextpoint:after {
	content: "\f105";
	margin: 0 0 0 6px
}
#streetView,
#geoLocation,
#scrollEnabling {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 999;
	font-size: 13px;
	line-height: 21px
}
#streetView:before,
#geoLocation:before,
#scrollEnabling:before {
	content: "\e015";
	font-family: simple-line-icons;
	position: relative;
	top: 2px;
	margin: 0 6px 0 0;
	font-size: 15px;
	line-height: 1px
}
#scrollEnabling:before {
	margin-left: -3px
}
#streetView:before {
	content: "\f21d";
	font-family: fontawesome;
	font-size: 16px;
	top: 1px;
	margin-right: 8px
}
#geoLocation {
	right: auto;
	left: 20px;
	padding: 8px 11px
}
#geoLocation:before {
	content: "\f192";
	font-family: fontawesome;
	font-size: 16px;
	margin: 0;
	top: 2px
}
@-webkit-keyframes passing-through {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		-moz-transform: translateY(40px);
		-ms-transform: translateY(40px);
		-o-transform: translateY(40px);
		transform: translateY(40px)
	}
	30%,
	70% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
		transform: translateY(0px)
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(-40px);
		-moz-transform: translateY(-40px);
		-ms-transform: translateY(-40px);
		-o-transform: translateY(-40px);
		transform: translateY(-40px)
	}
}
@-moz-keyframes passing-through {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		-moz-transform: translateY(40px);
		-ms-transform: translateY(40px);
		-o-transform: translateY(40px);
		transform: translateY(40px)
	}
	30%,
	70% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
		transform: translateY(0px)
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(-40px);
		-moz-transform: translateY(-40px);
		-ms-transform: translateY(-40px);
		-o-transform: translateY(-40px);
		transform: translateY(-40px)
	}
}
@keyframes passing-through {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		-moz-transform: translateY(40px);
		-ms-transform: translateY(40px);
		-o-transform: translateY(40px);
		transform: translateY(40px)
	}
	30%,
	70% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
		transform: translateY(0px)
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(-40px);
		-moz-transform: translateY(-40px);
		-ms-transform: translateY(-40px);
		-o-transform: translateY(-40px);
		transform: translateY(-40px)
	}
}
@-webkit-keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		-moz-transform: translateY(40px);
		-ms-transform: translateY(40px);
		-o-transform: translateY(40px);
		transform: translateY(40px)
	}
	30% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
		transform: translateY(0px)
	}
}
@-moz-keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		-moz-transform: translateY(40px);
		-ms-transform: translateY(40px);
		-o-transform: translateY(40px);
		transform: translateY(40px)
	}
	30% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
		transform: translateY(0px)
	}
}
@keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		-moz-transform: translateY(40px);
		-ms-transform: translateY(40px);
		-o-transform: translateY(40px);
		transform: translateY(40px)
	}
	30% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		-o-transform: translateY(0px);
		transform: translateY(0px)
	}
}
@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
	10% {
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-o-transform: scale(1.1);
		transform: scale(1.1)
	}
	20% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
}
@-moz-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
	10% {
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-o-transform: scale(1.1);
		transform: scale(1.1)
	}
	20% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
}
@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
	10% {
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-o-transform: scale(1.1);
		transform: scale(1.1)
	}
	20% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
}
.dropzone,
.dropzone * {
	box-sizing: border-box
}
.dropzone {
	min-height: 150px;
	border: 2px solid rgba(0, 0, 0, .3);
	background: #fff;
	padding: 20px
}
.dropzone.dz-clickable {
	cursor: pointer
}
.dropzone.dz-clickable * {
	cursor: default
}
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
	cursor: pointer
}
.dropzone.dz-started .dz-message {
	display: none
}
.dropzone.dz-drag-hover {
	border-style: solid
}
.dropzone.dz-drag-hover .dz-message {
	opacity: .5
}
.dropzone .dz-preview {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin: 16px;
	min-height: 100px
}
.dropzone .dz-preview:hover {
	z-index: 1000
}
.dropzone .dz-preview:hover .dz-details {
	opacity: 1
}
.dropzone .dz-preview.dz-file-preview .dz-image {
	border-radius: 3px;
	background: #999;
	background: linear-gradient(to bottom, #eee, #ddd)
}
.dropzone .dz-preview.dz-file-preview .dz-details {
	opacity: 1
}
.dropzone .dz-preview.dz-image-preview {
	background: #fff
}
.dropzone .dz-preview.dz-image-preview .dz-details {
	-webkit-transition: opacity .2s linear;
	-moz-transition: opacity .2s linear;
	-ms-transition: opacity .2s linear;
	-o-transition: opacity .2s linear;
	transition: opacity .2s linear
}
.dropzone .dz-preview .dz-remove {
	font-size: 14px;
	text-align: center;
	display: block;
	cursor: pointer;
	border: 0
}
.dropzone .dz-preview .dz-remove:hover {
	text-decoration: underline
}
.dropzone .dz-preview:hover .dz-details {
	opacity: 1
}
.dropzone .dz-preview .dz-details {
	z-index: 20;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	font-size: 13px;
	min-width: 100%;
	max-width: 100%;
	padding: 2em 1em;
	text-align: center;
	color: rgba(0, 0, 0, .9);
	line-height: 150%
}
.dropzone .dz-preview .dz-details .dz-size {
	margin-bottom: 1em;
	font-size: 16px
}
.dropzone .dz-preview .dz-details .dz-filename {
	white-space: nowrap
}
.dropzone .dz-preview .dz-details .dz-filename:hover span {
	border: 1px solid rgba(200, 200, 200, .8);
	background-color: rgba(255, 255, 255, .8)
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
	overflow: hidden;
	text-overflow: ellipsis
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
	border: 1px solid transparent
}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
	background-color: rgba(255, 255, 255, .4);
	padding: 0 .4em;
	border-radius: 3px
}
.dropzone .dz-preview:hover .dz-image img {
	-webkit-transform: scale(1.05, 1.05);
	-moz-transform: scale(1.05, 1.05);
	-ms-transform: scale(1.05, 1.05);
	-o-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
	-webkit-filter: blur(8px);
	filter: blur(8px)
}
.dropzone .dz-preview .dz-image {
	border-radius: 3px;
	overflow: hidden;
	width: 120px;
	height: 120px;
	position: relative;
	transition: all .3s;
	display: block;
	z-index: 10
}
.dropzone .dz-preview .dz-image img {
	display: block;
	filter: blur(0px);
	transition: all .4s
}
.dropzone .dz-preview.dz-success .dz-success-mark {
	-webkit-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
	-moz-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
	-ms-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
	-o-animation: passing-through 3s cubic-bezier(.77, 0, .175, 1);
	animation: passing-through 3s cubic-bezier(.77, 0, .175, 1)
}
.dropzone .dz-preview.dz-error .dz-error-mark {
	opacity: 1;
	-webkit-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
	-moz-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
	-ms-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
	-o-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
	animation: slide-in 3s cubic-bezier(.77, 0, .175, 1)
}
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
	pointer-events: none;
	opacity: 0;
	z-index: 500;
	position: absolute;
	display: block;
	top: 50%;
	left: 50%;
	margin-left: -27px;
	margin-top: -27px
}
.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
	display: block;
	width: 54px;
	height: 54px
}
.dropzone .dz-preview.dz-processing .dz-progress {
	opacity: 1;
	-webkit-transition: all .2s linear;
	-moz-transition: all .2s linear;
	-ms-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear
}
.dropzone .dz-preview.dz-complete .dz-progress {
	opacity: 0;
	-webkit-transition: opacity .4s ease-in;
	-moz-transition: opacity .4s ease-in;
	-ms-transition: opacity .4s ease-in;
	-o-transition: opacity .4s ease-in;
	transition: opacity .4s ease-in
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
	-webkit-animation: pulse 6s ease infinite;
	-moz-animation: pulse 6s ease infinite;
	-ms-animation: pulse 6s ease infinite;
	-o-animation: pulse 6s ease infinite;
	animation: pulse 6s ease infinite
}
.dropzone .dz-preview .dz-progress {
	opacity: 1;
	z-index: 1000;
	pointer-events: none;
	position: absolute;
	height: 16px;
	left: 50%;
	top: 50%;
	margin-top: -8px;
	width: 80px;
	margin-left: -40px;
	background: rgba(255, 255, 255, .9);
	-webkit-transform: scale(1);
	border-radius: 8px;
	overflow: hidden
}
.dropzone .dz-preview .dz-progress .dz-upload {
	background: #333;
	background: linear-gradient(to bottom, #666, #444);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 0;
	-webkit-transition: width 300ms ease-in-out;
	-moz-transition: width 300ms ease-in-out;
	-ms-transition: width 300ms ease-in-out;
	-o-transition: width 300ms ease-in-out;
	transition: width 300ms ease-in-out
}
.dropzone .dz-preview.dz-error .dz-error-message {
	display: block
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
	opacity: 1;
	pointer-events: auto
}
.dropzone .dz-preview .dz-error-message {
	pointer-events: none;
	z-index: 1000;
	position: absolute;
	display: block;
	display: none;
	opacity: 0;
	-webkit-transition: opacity .3s ease;
	-moz-transition: opacity .3s ease;
	-ms-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
	font-size: 13px;
	top: 130px;
	left: -10px;
	width: 140px;
	background: #be2626;
	background: linear-gradient(to bottom, #be2626, #a92222);
	border-radius: 4px;
	padding: 10px 15px;
	line-height: 18px;
	color: #fff
}
.dropzone .dz-preview .dz-error-message:after {
	content: '';
	position: absolute;
	top: -6px;
	left: 64px;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #be2626
}
.dropzone {
	position: relative;
	border: 2px dashed #d0d0d0;
	border-radius: 3px;
	background: #fcfcfc;
	transition: all .3s linear;
	margin-bottom: 10px;
	margin-top: 5px;
	display: inline-block;
	width: 100%
}
.dropzone:hover {
	border: 2px dashed #59b02c;
	background: #fff;
	transition: all .35s linear
}
.dropzone:before {
	position: absolute;
	height: 100%;
	width: 100%;
	content: "";
	top: 0;
	left: 0;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
	background-color: #59b02c;
	opacity: 0;
	z-index: 10;
	transition: all .3s linear;
	border-radius: 3px
}
.dropzone:hover:before {
	opacity: .4
}
.dropzone .dz-message {
	text-align: center;
	margin: 50px 0;
	font-size: 17px;
	color: #888;
	transition: all .3s linear;
	position: relative;
	z-index: 11
}
.dropzone:hover .dz-message {
	color: #59b02c
}
.dz-message i {
	display: block;
	font-size: 32px;
	margin-bottom: 15px
}
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;
	overflow: hidden;
	position: fixed;
	background: #000;
	opacity: .96
}
.mfp-wrap {
	top: -10px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2001;
	position: fixed;
	outline: 0!important;
	-webkit-backface-visibility: hidden
}
.mfp-container {
	height: 100%;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 18px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle
}
.mfp-align-top .mfp-container:before {
	display: none
}
.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 505
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto
}
.mfp-ajax-cur {
	cursor: progress
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out
}
.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in
}
.mfp-auto-cursor .mfp-content {
	cursor: auto
}
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none
}
.mfp-loading.mfp-figure {
	display: none
}
.mfp-hide {
	display: none!important
}
.mfp-preloader {
	color: #aaa;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -.8em;
	left: 8px;
	right: 8px;
	z-index: 504
}
.mfp-preloader a {
	color: #ccc
}
.mfp-preloader a:hover {
	color: #fff
}
.mfp-s-ready .mfp-preloader {
	display: none
}
.mfp-s-error .mfp-content {
	display: none
}
.mfp-s-loading .mfp-arrow {
	display: none
}
button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	border: 0;
	-webkit-appearance: none;
	display: block;
	padding: 0;
	z-index: 506
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	right: 0;
	top: -40px
}
.mfp-gallery .mfp-close {
	top: 20px;
	right: 10px
}
.mfp-gallery .mfp-content .mfp-close {
	display: none
}
.mfp-gallery .mfp-close:after,
.mfp-gallery .mfp-close:before {
	font-family: fontawesome;
	font-size: 29px;
	top: 4px;
	left: 50%;
	position: absolute;
	height: 10px;
	transition: .3s
}
#small-dialog .mfp-close,
#sign-in-dialog,
.mfp-close,
.mfp-close:hover {
	color: #fff
}
#sign-in-dialog .mfp-close,
#small-dialog .mfp-close {
	color: #666;
	background-color: #e4e4e4;
	border-radius: 50%;
	top: 28px;
	right: 40px;
	width: 40px;
	height: 40px
}
#sign-in-dialog .mfp-close:hover,
#small-dialog .mfp-close:hover {
	color: #fff;
	transform: rotate(0);
	background-color: #66676b
}
#sign-in-dialog .mfp-close::after,
#sign-in-dialog .mfp-close::before,
#small-dialog .mfp-close::after,
#small-dialog .mfp-close::before {
	top: 4px;
	height: 9px;
	font-size: 21px;
	transition: 0s
}
.small-dialog-content input[type=submit] {
	width: 100%;
	margin-top: 26px;
	margin-bottom: 10px
}
.small-dialog-content .divider {
	display: inline-block;
	width: 100%;
	margin-top: 15px;
	padding-bottom: 0
}
.small-dialog-header {
	font-size: 22px;
	width: calc(100% + 80px);
	position: relative;
	left: -40px;
	top: 0;
	border-radius: 4px 4px 0 0;
	display: inline-block;
	background-color: #f6f6f6;
	padding: 35px 40px;
	margin-bottom: 40px
}
.small-dialog-header h3 {
	padding: 0;
	margin: 0;
	font-size: 24px
}
.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #aaa;
	font-size: 13px;
	line-height: 18px
}
.mfp-arrow {
	opacity: 1;
	margin: 0 20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9999;
	color: #fff;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	cursor: pointer;
	width: 60px;
	height: 60px;
	position: absolute;
	display: block;
	z-index: 100;
	overflow: hidden;
	background: rgba(255, 255, 255, .15);
	border-radius: 50%;
	outline: 0
}
.mfp-arrow:hover {
	background: #66676b;
	color: #fff
}
.mfp-arrow.mfp-arrow-left,
.mfp-arrow.mfp-arrow-right {
	font-family: simple-line-icons;
	font-size: 20px
}
.mfp-arrow.mfp-arrow-right {
	right: 15px
}
.mfp-arrow.mfp-arrow-left:before {
	content: "\e605";
	left: -1px;
	top: 0;
	position: relative
}
.mfp-arrow.mfp-arrow-right:before {
	content: "\e606";
	left: 1px;
	top: 0;
	position: relative
}
.mfp-content:hover .mfp-arrow {
	opacity: 1
}
.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px
}
.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 1180px
}
.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 54%
}
.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, .6);
	background: #000
}
#sign-in-dialog,
#small-dialog {
	background: #fff;
	padding: 40px;
	padding-top: 0;
	text-align: left;
	max-width: 610px;
	margin: 40px auto;
	position: relative;
	box-sizing: border-box;
	border-radius: 4px
}
#sign-in-dialog {
	max-width: 500px
}
#sign-in-dialog span.line,
#small-dialog span.line {
	padding-top: 0
}
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-transform: scale(.8);
	-moz-transform: scale(.8);
	-ms-transform: scale(.8);
	-o-transform: scale(.8);
	transform: scale(.8)
}
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
}
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(.8);
	-moz-transform: scale(.8);
	-ms-transform: scale(.8);
	-o-transform: scale(.8);
	transform: scale(.8);
	opacity: 0
}
.my-mfp-zoom-in.mfp-bg {
	opacity: .001;
	-webkit-transition: opacity .3s ease-out;
	-moz-transition: opacity .3s ease-out;
	-o-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out
}
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: .8
}
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0
}
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 40px auto;
	border-radius: 4px
}
.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 18px rgba(11, 11, 11, .6)
}
.mfp-figure {
	line-height: 0
}
.mfp-bottom-bar {
	margin-top: -30px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto
}
.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-break: break-word;
	padding-right: 36px
}
.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px
}
.mfp-image-holder .mfp-content {
	max-width: 100%
}
.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer
}
@media screen and (max-width:800px) and (orientation:landscape),
screen and (max-height:300px) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, .6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, .6);
		position: fixed;
		text-align: center;
		padding: 0
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px
	}
}
.mfp-ie7 .mfp-img {
	padding: 0
}
.mfp-ie7 .mfp-bottom-bar {
	width: 600px;
	left: 50%;
	margin-left: -300px;
	margin-top: 5px;
	padding-bottom: 5px
}
.mfp-ie7 .mfp-container {
	padding: 0
}
.mfp-ie7 .mfp-content {
	padding-top: 44px
}
.mfp-ie7 .mfp-close {
	top: 0;
	right: 0;
	padding-top: 0
}
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	transition: all .2s ease-out
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: .92;
	background-color: #111
}
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0
}
.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	transition: all .2s ease-out
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0
}
.numerical-rating {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 8px;
	text-align: center;
	line-height: 26px;
	height: 26px;
	width: 44px;
	display: inline-block;
	font-family: open sans;
	position: relative;
	border-radius: 50px;
	letter-spacing: -.5px;
	background-color: rgba(255, 255, 255, .2);
	margin: 15px 32px
}
.compact .numerical-rating {
	margin: 0 0 8px
}
.numerical-rating:before {
	content: attr(data-rating)
}
.numerical-rating.high {
	background-color: #64bc36
}
.numerical-rating.mid {
	background-color: #c0c52a
}
.numerical-rating.low {
	background-color: #ee3535
}
.infoBox .numerical-rating .rating-counter,
.numerical-rating .rating-counter {
	position: absolute;
	top: 0;
	left: 14px;
	width: auto;
	display: inline-block;
	font-weight: 400;
	font-size: 15px;
	width: 150px;
	color: #909090
}
.infoBox .numerical-rating .rating-counter {
	left: 12px
}
.infoBox .numerical-rating {
	margin: 0;
	font-family: open sans
}
.star-rating {
	display: block;
	font-size: 17px
}
.listing-item-container .star-rating {
	padding: 15px 32px
}
.star-rating .star {
	display: inline-block;
	margin: 0;
	padding: 0;
	float: left;
	margin-right: 4px;
	position: relative
}
.rating-counter {
	color: #909090;
	padding-left: 5px;
	display: inline-block;
	font-size: 15px
}
.star-rating .rating-counter {
	top: -1px;
	position: relative
}
.infoBox .star-rating .rating-counter {
	font-size: 15px;
	top: -2px
}
.star-rating .star.half:after,
.star-rating .star:before {
	font-family: fontawesome;
	content: "\f005";
	display: block;
	color: #fe4f4f;
}
.star-rating .star.empty:before {
	color: #ddd
}
.rating:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0
}
.star-rating .star.half:before {
	color: #ddd
}
.star-rating .star.half:after {
	color: #fe4f4f;
	position: absolute;
	top: 0;
	width: 50%;
	display: block;
	height: 100%;
	overflow: hidden
}
/*---------------------------------------*/
/*--------- 17. PARALLAX IMAGE ----------*/
/*---------------------------------------*/ 
/* Search Form */
.search-2 .trip-search {
	background: #fff;
    padding: 12px;
    border: 1px solid rgba(255, 255, 255, 0.88);
    border-radius: 50px;
	text-align: left;
	margin-top: 50px;
}
.search-2 .trip-search .form {
	line-height: 0px;
}
.search-2 .form-group {
	width: 190px;
	display: inline-block;
	margin-right: 3px;
	margin-bottom: 0px;
}
.search-2 .form-group:last-child {
	margin-right: 0px;
}
.search-2 .form-group .nice-select {
	margin: 0;
	height: 50px;
	line-height: 50px;
	padding: 0 15px;
	border-radius: 5px;
	border: none;
	box-shadow: none;
	color: #757575;
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	font-size: 14px;
}
.search-2 .form-group .nice-select:hover {
	color: #fa2964;
}
.search-2 .form-group .nice-select::after {
	right: 20px;
	color: #757575;
}
.search-2 .form-group .nice-select i {
	color: #fa2964;
	font-size: 1.2rem;
	margin-top: 5px;
	margin-right: 10px;
}
.search-2 .form-group .list li {
	color: #757575;
}
.search-2 .form-group .list li.selected {
	color: #757575;
	font-weight: 400;
}
.search-2 .form-group .list li:hover {
	color: #fff;
	background: #fa2964;
}
.search-2 .form-group.looking {
	width: 350px;
	margin: 0px;
}
.search-2 .first-select {
	height: 30px;
    border-radius: 5px;
	padding-right: 3px;
    border: none;
    box-shadow: none;
    color: #757575;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.main-search-input-item {
    float: left;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    position: relative;
}
.nice-select.wide {
    width: 100%;
}
.search-2 .form-group.looking input {
	float: left;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 50px;	
    padding-left: 20px;
}
.search-2 .form-group.location {
	width: 270px;
}
.search-2 .form-group.categories {
	width: 288px;
}
.search-2 .form-group.button {
	width: 145px;
	position: relative;
	height: 50px;
}
.search-2 .form-group .btn {
	display: block;
	width: 100%;
	background: #fa2964;
	cursor: pointer;
	color: #fff;
	text-transform: uppercase;
	font-weight: 600;
	position: absolute;
	top: 0;
	height: 50px;
	line-height: 50px;
	padding: 0;
	font-size: 14px;
	border: none;
	border-radius: 50px;
}
.search-2 .form-group .btn:hover {
	background: #121B22;
	color: #fff;
}
@media only screen and (max-width: 1024px) {
    .search-2 .trip-search .form-group {
        margin-top: 15px;
		margin-right: 20px;
        width: 100%;
	}
}
@media only screen and (max-width: 991px) {	
	.search-2 .hero-inner {
        margin: 97px 0;
	}
    .search-2 .trip-search {
        border-radius: 3px;
    }
    .hero-inner2 {
        margin-top: 550px;
    }
    .header.vid {
        position: relative;
        background: #121B22;
    }
    .dark-overlay h1 {
        font-size: 40px;
    }
    .homepage-1 .bg-all {
        margin-top: 0px !important;
    }
}
@media only screen and (max-width: 767px) {
	.search-2 .welcome-text h1 {
        font-size: 48px;
	}
	.search-2 .hero-inner {
        margin: 69px 0;
	}
	.search-2 .trip-search .form-group {
        margin-top: 10px;
        width: 100%;
	}
}
@media only screen and (max-width: 576px) {
    .dark-overlay h1 {
        font-size: 30px !important;
    }
}
@media only screen and (max-width: 450px) {
	.search-2 .welcome-text p {
        font-size: 14px;
	}
    .hero-inner2 {
        margin-top: 400px;
    }
	.search-2 .hero-inner {
        margin: 70px 0;
	}
	.search-2 .trip-search {
        margin-top: 30px;
	}
    #map-container.fullwidth-home-map .main-search-inner.vid {
        position: relative;
    }
    .dark-overlay h1 {
        font-size: 21px !important;
    }
}
.homepage-9 .sec-title {
    text-align: center;
    padding-bottom: 2rem;
}
.homepage-9 .sec-title h2 {
    color: #111;
    font-weight: 600;
    text-transform: capitalize;
}
.homepage-9 .sec-title h2 span {
    position: relative;
    color: #444;
    font-weight: 500;
}
.homepage-9 .sec-title p {
    margin-top: 13px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    max-width: 550px;
}
.homepage-9 .portfolio .homes-tag.sale {
    background: rgba(74,74,76,0.7) !important;
}
.homepage-9 .homes-tag.featured {
    background: #FF385C !important;
}
.homepage-9 .portfolio .homes-price {
    font-size: 20px !important;
    font-weight: 500;
    background: transparent !important;
}
.homepage-9 .featured .project-single .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.homepage-9 .featured .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: transparent;
    transform: translateX(0%);
    opacity: 0;
    visibility: visible;
    padding: 8px 0px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.homepage-9 .featured .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    line-height: 33px;
    background: rgba(74,74,76,0.7);
    z-index: 1;
}
.homepage-9 .featured .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-9 .featured .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-9 .featured .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-9 .portfolio .project-head {
    background-color: #1d293e;
    border-radius: 8px 8px 0 0;
}
.homepage-9 .portfolio .homes-price {
    padding-left: 0px;
}
.homepage-9 .portfolio .project-head:before {
    background: transparent !important;
}
.homepage-9 .portfolio .project-head img {
    background-color: #1d293e;
    opacity: .75;
}
.homepage-9.homepage-4 a.button.border, a.button {
    width: 150px;
    background: #FF385C;
    color: #fff !important;
}
.homepage-9.homepage-4 .portfolio .project-single {
    border-radius: 8px;
    border: 0px;
}
.homepage-9.homepage-4 .portfolio .price-properties {
    border-top: 1px solid #eee;
}
.homepage-9.homepage-4 .slick-dots li:after {
    background: #fff;
}
.homepage-9.homepage-4 .head-tr.bottom {
    background: rgba(255, 255, 255, 0.12);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.homepage-9.homepage-4 .homes-content .homes-list li i {
    color: #666;
}
.homepage-9.homepage-4 .project-single .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.homepage-9.homepage-4 .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(0%) !important;
    opacity: 0;
    visibility: visible;
    padding: 8px 0px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.homepage-9.homepage-4 .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    line-height: 33px;
    background: rgba(74,74,76,0.7);
    z-index: 99;
}
.homepage-9.homepage-4 .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-9.homepage-4 .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-9.homepage-4 .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-9.homepage-4 .project-head {
    background-color: #1d293e;
    border-radius: 8px 8px 0 0;
}
.homepage-9.homepage-4 .homes-price {
    padding-left: 0px;
}
.homepage-9.homepage-4 .project-head:before {
    background: transparent !important;
}
.homepage-9.homepage-4 img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.homepage-9 .portfolio .project-single {
    border-radius: 6px;
}
.homepage-9 .portfolio .homes-content .homes-address .fa-map-marker {
    color: #666;
}
.homepage-9 .portfolio .homes-content h3 a:hover,
.homepage-9 .blog-section .news-item h3:hover {
    color: #FF385C;
}
.homepage-9 .portfolio .footer img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
/*----------------------------------------------*/
/*------ 11. FEATURED CATEGORIES HOME 3 ------*/
/*----------------------------------------------*/
section.feature-categories {
    padding: 4.6rem 0;
}
.homepage-9 .small-category-2 {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 0px;
    align-items: center;
    display: flex;
}
.homepage-9 .small-category-2-thumb .fas {
    font-size: 2.1rem;
    color: firstcolor;
}
.homepage-9 .small-category-2-thumb {
    width: 170px;
    float: left;
    display: table;
    height: 150px;
    background: #fff;
    padding: 0px;
    border-radius: 50%;
}
.homepage-9 .small-category-2-thumb img {
    width: 170px;
    height: 150px;
}
.homepage-9 .sc-2-detail {
    display: table;
    margin-left: 25px;
    float: left;
}
.homepage-9 .sc-2-detail .sc-jb-title {
    margin-bottom: 4px;
}
.homepage-9 h4.sc-jb-title a {
    font-size: 16px;
    color: #555;
    font-weight: 400;
    text-decoration: none;
    transition: all .3s ease;
}
.homepage-9 h4.sc-jb-title a:hover {
    color: #fa2964;
}
.homepage-9 .sc-2-detail span {
    font-size: 15px;
    font-weight: 400;
    color: text;
}
.homepage-9 .no-mb {
    margin-bottom: 0px;
}
@media screen and(max-width: 767px) {
    .homepage-9 .si-mt {
        margin-top: 30px;
    }
    .homepage-9 .how-it-works article i {
        font-size: 2.5rem !important;
    }
}
@media screen and(max-width: 575px) {
    .homepage-9 .mob-mt {
        margin-bottom: 30px;
    }
    .homepage-9.homepage-4 a.button.border, a.button {
        width: 130px !important;
    }
}
@media screen and (max-width: 991px) {
    .homepage-9 .m-t-30 {
        margin-top: 30px;
    }
    .homepage-9.homepage-4 a.button.border, 
    a.button {
        width: 240px;
    }
}
.homepage-9 .rec-pro .container-fluid {
    max-width: 1560px;
}
.homepage-9 section.portfolio {
    padding: 4.6rem 0;
    background: #FF385C;
}
.homepage-9 .sec-title.discover h2 span,
.homepage-9 .sec-title.discover h2,
.homepage-9 .sec-title.discover p {
    color: #fff;
}
.homepage-9 .slick-dots li.slick-active,
.homepage-9  .slick-dots li {
    box-shadow: inset 0 0 0 3px rgba(255,255,255,0.5);
}
.homepage-9 .slick-dots li:after {
    background: #eee;
}
.homepage-9 .fd-1 .slick-dots li:after {
    background: #FF385C;
}
.homepage-9 .portfolio .project-single {
    box-shadow: none;
    margin-bottom: 0px;
    border: 1px solid #e7e7e7;
}
.homepage-9 .portfolio .footer span {
    margin-top: 12px
}
.homepage-9 .portfolio .item {
    padding-bottom: 30px;
}
.homepage-9 .bg-all {
    margin-top: 0px;
}
.homepage-9 .portfolio.disc .project-single {
    border: 0px;
}